export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "VENTAS_PERIODO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de ventas por período"])},
          "VENTAS_ARTICULO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de ventas por artículo"])},
          "VENTAS_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de ventas por cliente"])},
          "VENTAS_SALESX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de ventas generales por ..."])},
          "VENTAS_COMPARACION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de ventas por comparación"])},
          "VENTAS_AGENTX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas por agentes"])},
          "COMPRAS_PERIODO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de compras por período"])},
          "COMPRAS_ARTICULO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de compras por articulo"])},
          "NAME_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
          "NAME_AGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
          "NAME_UNIDAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad"])},
          "NAME_CASHIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caja"])},
          "NAME_COMPLETO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])},
          "NAME_SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
          "NAME_PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "NAME_FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "NAME_CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase"])},
          "NAME_LINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea"])},
          "NAME_FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
          "TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos"])},
          "TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de transacción"])},
          "REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte por:"])}
        },
        "BUTTONS": {
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
          "GENERATE_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar reporte"])},
          "GENERATE_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar excel"])}
        }
      }
    }
  })
}
