<script>
import DatePickerRange from "@/components/forms/DatePickerRange.vue";
import { Form } from 'vee-validate'
import {computed, defineComponent, onMounted, ref } from "vue";
import tableModule from "@/store/modules/werehouse/modules/inputs-table";
import * as Validations from "yup";
import debounce from 'lodash/debounce';
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue";
import Select from "@/components/forms/Select.vue";
import ApiService from "@/core/services/ApiService";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import moduleForm from "@/store/modules/cashiers/modules/new-cashiers";
import InputText from "@/components/forms/InputText.vue";

export default defineComponent({
    components: {
        InputText,
        Select,
        SelectFilterRemote,
        DatePickerRange,
        Form
    },
    emits: ['emitData'],
    setup(_, { emit }){

        onMounted(() => {
            resetFilterDateInitial(formFilters12)
            resetFilterDateInitial(formFilters13)
        });

        const loading9 = ref(false);
        const loading9_1 = ref(false);
        const loading10 = ref(false);
        const loading10_1 = ref(false);
        const loading11 = ref(false);
        const loading11_1 = ref(false);
        const loading12 = ref(false);
        const loading12_1 = ref(false);
        const loading13 = ref(false);
        const loading13_1 = ref(false);

        const formFilters9 = ref();
        const formFilters10 = ref();
        const formFilters11 = ref();
        const formFilters12 = ref();
        const formFilters13 = ref();

        const btnFilters9 = ref();
        const btnFilters10 = ref();
        const btnFilters11 = ref();
        const btnFilters12 = ref();
        const btnFilters13 = ref();

        const btnFilters9_1 = ref();
        const btnFilters10_1 = ref();
        const btnFilters11_1 = ref();
        const btnFilters12_1 = ref();
        const btnFilters13_1 = ref();
        const selectionAction = ref();

        const idCollapse9 = ref('collapse-inputs-filters9');
        const idCollapse10 = ref('collapse-inputs-filters10');
        const idCollapse11 = ref('collapse-inputs-filters11');
        const idCollapse12 = ref('collapse-inputs-filters12');
        const idCollapse13 = ref('collapse-inputs-filters13');

        const messageService = new SwalMessageService();
        const moduleProduct = getModule(NewProductEntryModule);

        let suppliers = ref("");
        let cashiers = ref("");
        let family = ref("");
        let subfamily = ref("");
        let linea = ref("");
        let unidad = ref("");
        const comboUnidad = computed(() => { return unidad.value });
        const comboSupplier = computed(() => { return suppliers.value });
        const comboCashier = computed(() => { return cashiers.value });
        const comboFamily = computed(() => { return family.value });
        const comboSubFamily = computed(() => { return subfamily.value });
        const comboLine = computed(() => { return linea.value });
        const customerOptions = computed(() => tableModule.selects.customerOptions)
        const userOptions = computed(() => moduleForm.selects.userOptions);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);

        const typeReport9 = [
            { id: 0, name: "Familia"},
            { id: 1, name: "Subfamilia"},
            { id: 2, name: "Línea"},
            { id: 3, name: "Existencia"}
        ]

        const validationSchemaFilters9 = Validations.object().shape({
        })

        const validationSchemaFilters10 = Validations.object().shape({
        })

        const validationSchemaFilters11 = Validations.object().shape({
        })

        const validationSchemaFilters12 = Validations.object().shape({
        })

        const validationSchemaFilters13 = Validations.object().shape({
        })

        const searchArticle = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm9(filters.reportArticle, filters.familyIds, filters.classIds, filters.lineIds, filters.productIds, filters.unidadIds);
            } else {
                await PDFForm9(filters.reportArticle, filters.familyIds, filters.classIds, filters.lineIds, filters.productIds, filters.unidadIds);
            }
        }

        const searchArticlePrice = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm10(filters.rangoInicio, filters.rangoFin, filters.familyIds, filters.classIds, filters.lineIds, filters.productIds, filters.unidadIds);
            } else {
                await PDFForm10(filters.rangoInicio, filters.rangoFin, filters.familyIds, filters.classIds, filters.lineIds, filters.productIds, filters.unidadIds);;
            }
        }

        const searchArticleMovements = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm11(filters.productIds, filters.unidadIds);
            } else {
                await PDFForm11(filters.productIds, filters.unidadIds);
            }
        }

        const searchKardex = async (filters) => {
            await PDFForm12(filters.productIds, filters.startDate, filters.endDate);
        }

        const searchResurtido = async (filters) => {
            await ExcelForm13(filters.startDate, filters.endDate, filters.supplierIds, filters.productIds);
        }

        const ExcelForm9 = async (typeReport, familia, clase, linea, producto, unidad) => {
            let urls = `Reports/ReportArticleExcel?ReportArticle=${typeReport}` +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (linea ? `&LineaId=${linea}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            await procesoExcel(loading9_1, urls, 'Reporte_Articulos.xlsx', resetFilterForm9);
        }

        const ExcelForm10 = async (costoInicio, costoFinal, familia, clase, linea, producto, unidad) => {
            let exist = "";
            if(costoInicio != null || costoFinal != null || familia != null || clase != null || linea != null || producto != null || unidad != null){
                exist = "?";
            }

            let urls = `Reports/ReportArticlePriceExcel${exist}` +
                (costoInicio ? `&RangoInicioCosto=${costoInicio}` : '') +
                (costoFinal ? `&RangoInicioCosto=${costoFinal}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (linea ? `&LineaId=${linea}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            await procesoExcel(loading10_1, urls, 'Reporte_Articulos.xlsx', resetFilterForm10);
        }

        const ExcelForm11 = async (producto, unidad) => {
            let exist = "";
            if(producto != null || unidad != null){
                exist = "?";
            }

            let urls = `Reports/ReportArticleMovementsExcel${exist}` +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            await procesoExcel(loading11_1, urls, 'Reporte_Articulos.xlsx', resetFilterForm11);
        }

        const ExcelForm13 = async (inicio, final, supplier, product) => {
            let urls = `Reports/Replenishment?StartDate=${inicio}&EndDate=${final}`;

            // Si hay productos, los añadimos a la URL
            if (product !== undefined) {
                urls += `&${product.map(productId => `Products=${productId}`).join('&')}`;
            }

            // Si hay proveedores, los añadimos a la URL
            if (supplier !== undefined) {
                urls += `&${supplier.map(supplierId => `Supplier=${supplierId}`).join('&')}`;
            }

            await procesoExcel(loading13_1, urls, 'Resurtido.xlsx', resetFilterForm13);
        }

        const PDFForm9 = async (typeReports, familia, clase, linea, producto, unidad) => {
            let url = `Reports/ReportArticle?ReportArticle=${typeReports}` +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (linea ? `&LineaId=${linea}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            let nombre = "Reporte de articulo por " + typeReport9.find(x => x.id === typeReports).name;
            await procesoPdf(loading9, url, nombre, resetFilterForm9);
        }

        const PDFForm10 = async (costoInicio, costoFinal, familia, clase, linea, producto, unidad) => {
            let exist = "";
            if(costoInicio != null || costoFinal != null || familia != null || clase != null || linea != null || producto != null || unidad != null){
                exist = "?";
            }

            let url = `Reports/ReportArticlePrice${exist}` +
                (costoInicio ? `&RangoInicioCosto=${costoInicio}` : '') +
                (costoFinal ? `&RangoInicioCosto=${costoFinal}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (linea ? `&LineaId=${linea}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            let nombre = "Reporte de articulo por precio";
            await procesoPdf(loading10, url, nombre, resetFilterForm10);
        }

        const PDFForm11 = async (producto, unidad) => {
            let exist = "";
            if(producto != null || unidad != null){
                exist = "?";
            }

            let url = `Reports/ReportArticleMovements${exist}` +
                (producto ? `&ProductoId=${producto}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '');

            let nombre = "Reporte de articulo por movimientos";
            await procesoPdf(loading11, url, nombre, resetFilterForm11);
        }

        const PDFForm12 = async (product, inicio, final) => {
            let url = `Reports/Kardex?Id=${product}&Inicio=${inicio}&Final=${final}`;

            let nombre = "Kardex";
            await procesoPdf(loading12, url, nombre, resetFilterForm12);
        }

        const procesoExcel = async (loading, urls, name, reset) => {
            loading.value = true;

            const res = await ApiService.dowloadFileGet(urls);
            const url = window.URL.createObjectURL(res.data);

            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.remove();

            loading.value = false;
            reset();
        }

        const procesoPdf = async (loading, url, name, reset) => {
            loading.value = true;

            const res = await ApiService.query(url);
            emit("emitData", res.data, name)

            loading.value = false;
            reset();
        }

        const resetFilterForm9 = () => {
            console.log("Hi")
        }

        const resetFilterForm10 = () => {
            console.log("Hi")
        }

        const resetFilterForm11 = () => {
            console.log("Hi")
        }

        const resetFilterForm12 = () => {
            console.log("Hi")
        }

        const resetFilterForm13 = () => {
            console.log("Hi")
        }

        const resetFilterDateInitial = (formFilter) => {
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 120);

            if (formFilter.value) {
                formFilter.value.setFieldValue('startDate', startDate.toISOString());
                formFilter.value.setFieldValue('endDate', endDate.toISOString());
            }
        };

        const resetxFamily5 = () => {
            if (formFilters9.value) {
                formFilters9.value.setFieldValue('classIds', '');
                formFilters9.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass5 = () => {
            if (formFilters9.value) {
                formFilters9.value.setFieldValue('lineIds', '');
            }
        }

        const resetxFamily6 = () => {
            if (formFilters10.value) {
                formFilters10.value.setFieldValue('classIds', '');
                formFilters10.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass6 = () => {
            if (formFilters10.value) {
                formFilters10.value.setFieldValue('lineIds', '');
            }
        }

        //Combo options
        const getComboOptions = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getCombFamily = (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combos?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboLine = (resource, container, parentId = "", subParentId = "", filter = "") => {
            let url = `${resource}/${parentId}/${subParentId}/combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboUnidad = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        //Buscadores
        const handleSearchProducts = (name) => {
            if(name.trim().length >= 3){
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const searchSuppliers = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const searchUnidad = async (filter) => {
            await getComboUnidad("MeasurementUnits", unidad, "", filter);
        }

        const searchCasher = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("Cashiers", cashiers, "", filter);
            }
        }

        const searchFamily = async (filter) => {
            if (filter.trim().length >= 3) {
                await getCombFamily("Families", family, "", filter);
            }
        }

        const searchSubFamily5 = async (filter) => {
            if(formFilters9.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters9.value.values.familyIds, filter);
            }
        }

        const searchLinea5 = async (filter) => {
            if(formFilters9.value.values.familyIds != null && formFilters9.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters9.value.values.familyIds, formFilters9.value.values.classIds, filter);
            }
        }

        const searchSubFamily6 = async (filter) => {
            if(formFilters10.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters10.value.values.familyIds, filter);
            }
        }

        const searchLinea6 = async (filter) => {
            if(formFilters10.value.values.familyIds != null && formFilters10.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters10.value.values.familyIds, formFilters10.value.values.classIds, filter);
            }
        }

        const searchUsers = (filterName) => {
            if (filterName.trim().length >= 3) {
                moduleForm.getComboUser(filterName);
            }
        }

        const searchCustomers = debounce((filterText) => {
            if (filterText.trim().length >= 3) {
                tableModule.getComboCustomer(filterText);
            }
        }, 550, { leading: false, trailing: true });

        return {
            idCollapse9,
            idCollapse10,
            idCollapse11,
            idCollapse12,
            idCollapse13,
            searchArticle,
            searchArticlePrice,
            searchArticleMovements,
            searchKardex,
            searchResurtido,
            formFilters9,
            formFilters10,
            formFilters11,
            formFilters12,
            formFilters13,
            btnFilters9,
            btnFilters10,
            btnFilters11,
            btnFilters12,
            btnFilters13,
            btnFilters9_1,
            btnFilters10_1,
            btnFilters11_1,
            btnFilters12_1,
            btnFilters13_1,
            selectionAction,
            validationSchemaFilters9,
            validationSchemaFilters10,
            validationSchemaFilters11,
            validationSchemaFilters12,
            validationSchemaFilters13,
            customerOptions,
            typeReport9,
            productsCombo,
            productsLoading,
            userOptions,
            comboSupplier,
            comboCashier,
            comboFamily,
            comboSubFamily,
            comboLine,
            comboUnidad,
            handleSearchProducts,
            searchCustomers,
            searchSuppliers,
            searchUsers,
            searchCasher,
            searchUnidad,
            searchFamily,
            searchSubFamily5,
            searchLinea5,
            searchSubFamily6,
            searchLinea6,
            resetxFamily5,
            resetxClass5,
            resetxFamily6,
            resetxClass6,
            loading9,
            loading9_1,
            loading10,
            loading10_1,
            loading11,
            loading11_1,
            loading12,
            loading12_1,
            loading13,
            loading13_1,
        };
    },
    watch: {
        'formFilters9.values.familyIds': function(newVal) {
            this.resetxFamily5();
        },
        'formFilters9.values.classIds': function(newVal) {
            this.resetxClass5();
        },
        'formFilters10.values.familyIds': function(newVal) {
            this.resetxFamily6();
        },
        'formFilters10.values.classIds': function(newVal) {
            this.resetxClass6();
        },
    }
});
</script>

<template>
    <div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse9}`"
                aria-expanded="true"
                :aria-controls="idCollapse9"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.ARTICULOS') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse9">
                <Form as="el-form" @submit="searchArticle" :validation-schema="validationSchemaFilters9"  ref="formFilters9">
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select :name="'reportArticle'" :options="typeReport9">
                            </Select>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_UNIDAD') }}</label>
                            <SelectFilterRemote :name="'unidadIds'" :options="comboUnidad"
                                                @search="searchUnidad"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily5"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea5"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters9_1" @click="selectionAction = 'excel'">
                            <span v-if="loading9_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters9" @click="selectionAction = 'report'">
                            <span v-if="loading9" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse10}`"
                aria-expanded="true"
                :aria-controls="idCollapse10"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.ARTICULOS_PRICE') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse10">
                <Form as="el-form" @submit="searchArticlePrice" :validation-schema="validationSchemaFilters10"  ref="formFilters10">
                    <div class="row g-9 mb-8">
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_UNIDAD') }}</label>
                            <SelectFilterRemote :name="'unidadIds'" :options="comboUnidad"
                                                @search="searchUnidad"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.RANGO_INICIO') }}</label>
                            <InputText :name="'rangoInicio'">

                            </InputText>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.RANGO_FINAL') }}</label>
                            <InputText :name="'rangoFin'">

                            </InputText>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily6"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea6"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters10_1" @click="selectionAction = 'excel'">
                            <span v-if="loading10_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters10" @click="selectionAction = 'report'">
                            <span v-if="loading10" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse11}`"
                aria-expanded="true"
                :aria-controls="idCollapse11"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.ARTICULOS_MOVEMENTS') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse11">
                <Form as="el-form" @submit="searchArticleMovements" :validation-schema="validationSchemaFilters11"  ref="formFilters11">
                    <div class="row g-9 mb-8">
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_UNIDAD') }}</label>
                            <SelectFilterRemote :name="'unidadIds'" :options="comboUnidad"
                                                @search="searchUnidad"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters11_1" @click="selectionAction = 'excel'">
                            <span v-if="loading11_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters11" @click="selectionAction = 'report'">
                            <span v-if="loading11" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse12}`"
                aria-expanded="true"
                :aria-controls="idCollapse12"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.KARDEX') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse12">
                <Form as="el-form" @submit="searchKardex" :validation-schema="validationSchemaFilters12"  ref="formFilters12">
                    <div class="row g-9 mb-8">
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-6 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success" ref="btnFilters12">
                            <span v-if="loading12" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse13}`"
                aria-expanded="true"
                :aria-controls="idCollapse13"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.RESURTIDO') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse13">
                <Form as="el-form" @submit="searchResurtido" :validation-schema="validationSchemaFilters13"  ref="formFilters13">
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :multiple="true"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_SUPPLIER') }}</label>
                            <SelectFilterRemote :name="'supplierIds'" :options="comboSupplier"
                                                @search="searchSuppliers"
                                                :multiple="true"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters13_1">
                            <span v-if="loading13_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "es": {
        "LABELS": {
            "ARTICULOS": "Reporte de articulos",
            "CANTIDAD": "Total de articulos",
            "ARTICULOS_PRICE": "Reporte de articulos por precio",
            "RANGO_INICIO": "Rango de precio inicial",
            "RANGO_FINAL": "Rango de precio final",
            "ARTICULOS_MOVEMENTS": "Reporte de articulos por movimientos",
            "KARDEX": "Kardex",
            "RESURTIDO": "Resurtido",
            "NAME_CUSTOMER": "Clientes",
            "NAME_AGENT": "Agente",
            "NAME_UNIDAD": "Unidad",
            "NAME_CASHIER": "Caja",
            "NAME_COMPLETO": "Estatus",
            "NAME_SUPPLIER": "Proveedor",
            "NAME_PRODUCT": "Producto",
            "NAME_FAMILY": "Familia",
            "NAME_CLASS": "Clase",
            "NAME_LINE": "Línea",
            "NAME_FOLIO": "Folio",
            "DATE": "Fecha",
            "TAX": "Impuestos",
            "TRANSACTION": "Tipo de transacción",
            "REPORT": "Reporte por:"
        },
        "BUTTONS": {
            "CLEAN_FILTERS_FORM": "Limpiar",
            "GENERATE_REPORT": "Generar reporte",
            "GENERATE_EXCEL": "Generar excel"
        }
    }
}
</i18n>

<style scoped>
    .color-text{
        color: rgba(0,0,0,.5);
    }
</style>