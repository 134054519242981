<script>
import DatePickerRange from "@/components/forms/DatePickerRange.vue";
import { Form } from 'vee-validate'
import {computed, defineComponent, onMounted, ref } from "vue";
import tableModule from "@/store/modules/werehouse/modules/inputs-table";
import * as Validations from "yup";
import debounce from 'lodash/debounce';
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue";
import Select from "@/components/forms/Select.vue";
import ApiService from "@/core/services/ApiService";
import {getModule} from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import {getMessageError} from "@/core/helpers/messageFromPulmeros";
import SwalMessageService from "@/core/services/SwalMessageService";
import moduleForm from "@/store/modules/cashiers/modules/new-cashiers";
import InputText from "@/components/forms/InputText.vue";

export default defineComponent({
    components: {
        InputText,
        Select,
        SelectFilterRemote,
        DatePickerRange,
        Form
    },
    emits: ['emitData'],
    setup(_, { emit }) {

        // Saber que se debe de ver en el formulario 1
        const form7 = ref();

        const isViewBtn7 = computed(() => { return form7.value === 2 || form7.value == 3 ? true : false; })

        onMounted(() => {
            resetFilterDateInitial(formFilters7)
            resetFilterDateInitial(formFilters8)
        });

        const loading7 = ref(false);
        const loading7_1 = ref(false);
        const loading8 = ref(false);
        const loading8_1 = ref(false);

        const formFilters7 = ref();
        const formFilters8 = ref();

        const btnFilters7 = ref();
        const btnFilters8 = ref();

        const btnFilters7_1 = ref();
        const btnFilters8_1 = ref();
        const selectionAction = ref();

        const idCollapse7 = ref('collapse-inputs-filters7');
        const idCollapse8 = ref('collapse-inputs-filters8');

        const messageService = new SwalMessageService();
        const moduleProduct = getModule(NewProductEntryModule);

        let suppliers = ref("");
        let cashiers = ref("");
        let family = ref("");
        let subfamily = ref("");
        let linea = ref("");
        let unidad = ref("");
        const comboUnidad = computed(() => { return unidad.value });
        const comboSupplier = computed(() => { return suppliers.value });
        const comboCashier = computed(() => { return cashiers.value });
        const comboFamily = computed(() => { return family.value });
        const comboSubFamily = computed(() => { return subfamily.value });
        const comboLine = computed(() => { return linea.value });
        const customerOptions = computed(() => tableModule.selects.customerOptions)
        const userOptions = computed(() => moduleForm.selects.userOptions);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);

        // Type report para las compras
        const typeStatus = [
            { id: false, name: "Todos"},
            { id: true, name: "Completos"}
        ]

        const typeReport7 = [
            { id: 0, name: "Mes"},
            { id: 1, name: "Dia"},
            { id: 2, name: "Folio"},
            { id: 3, name: "Detallado"},
        ]

        const typeReport8 = [
            { id: 0, name: "Resumen"},
            { id: 1, name: "Detallado"}
        ]

        //Validation scheme para las compras
        const validationSchemaFilters7 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , reportPurchase: Validations.string().default('').required("Selecciona una opción")
            , supplierIds: Validations.string().default('').optional()
            , folio: Validations.string().default('').optional()
        })

        const validationSchemaFilters8 = Validations.object().shape({
            startDate: Validations.date().required("La fecha es obligatoria")
            , endDate: Validations.date().required("La fecha es obligatoria")
            , reportPurchase: Validations.string().default('').required("Selecciona una opción")
            , typeStatus: Validations.bool().default('').required("Selecciona una opción")
            , supplierIds: Validations.string().default('').optional()
            , productIds: Validations.string().default('').optional()
            , familyIds: Validations.string().default('').optional()
                .when('classIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una clase")
                })
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La familia es obligatorio si se ingresa una linea")
                })
            , classIds: Validations.string().default('').optional()
                .when('lineIds', {
                    is: (value) => value !== '',
                    then: (schema) => schema.required("La clase es obligatorio si se ingresa una linea")
                })
            , lineIds: Validations.string().default('').optional()
        })

        // Funciones para generar los reportes sobre compras
        const searchPurchasePeriod = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm7(filters.startDate, filters.endDate, filters.reportPurchase, filters.supplierIds, filters.folio);
            } else {
                await PDFForm7(filters.startDate, filters.endDate, filters.reportPurchase, filters.supplierIds, filters.folio);
            }
        }

        const searchPurchaseArticle = async (filters) => {
            if(selectionAction.value === 'excel'){
                await ExcelForm8(filters.startDate, filters.endDate, filters.reportPurchase, filters.typeStatus, filters.supplierIds, filters.productIds, filters.familyIds, filters.classIds, filters.lineIds, filters.unidadIds);
            } else {
                await PDFForm8(filters.startDate, filters.endDate, filters.reportPurchase, filters.typeStatus, filters.supplierIds, filters.productIds, filters.familyIds, filters.classIds, filters.lineIds, filters.unidadIds);
            }
        }

        // Excel y pdf generators
        const ExcelForm7 = async (inicio, final, typeReports, proveedor, folio) => {
            let urls = `Reports/ReportPurchasePeriodExcel?Inicio=${inicio}&Final=${final}&ReportPurchase=${typeReports}` +
                (proveedor ? `&SupplierId=${proveedor}` : '') +
                (folio ? `&Folio=${folio}` : '');

            await procesoExcel(loading7_1, urls, 'Reporte_Compras.xlsx', resetFilterForm7);
        }

        const ExcelForm8 = async (inicio, final, typeReports, typeStatus, proveedor, producto, familia, clase, linea, unidad) => {
            let urls = `Reports/ReportPurchaseArticleExcel?Inicio=${inicio}&Final=${final}&ReportPurchase=${typeReports}&Completos=${typeStatus}` +
                (proveedor ? `&ProveedorId=${proveedor}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '') +
                (linea ? `&LineaId=${linea}` : '');

            await procesoExcel(loading8_1, urls, 'Reporte_Compras.xlsx', resetFilterForm8);
        }

        const PDFForm7 = async (inicio, final, typeReports, proveedor, folio) => {
            let url = `Reports/ReportPurchasePeriod?Inicio=${inicio}&Final=${final}&ReportPurchase=${typeReports}` +
                (proveedor ? `&SupplierId=${proveedor}` : '') +
                (folio ? `&Folio=${folio}` : '');

            let nombre = "Compras por " + typeReport7.find(x => x.id === typeReports).name;
            await procesoPdf(loading7, url, nombre, resetFilterForm7);
        }

        const PDFForm8 = async (inicio, final, typeReports, typeStatus, proveedor, producto, familia, clase, linea, unidad) => {
            let url = `Reports/ReportPurchaseArticle?Inicio=${inicio}&Final=${final}&ReportPurchase=${typeReports}&Completos=${typeStatus}` +
                (proveedor ? `&ProveedorId=${proveedor}` : '') +
                (producto ? `&ProductoId=${producto}` : '') +
                (familia ? `&FamiliaId=${familia}` : '') +
                (clase ? `&ClaseId=${clase}` : '') +
                (unidad ? `&UnidadId=${unidad}` : '') +
                (linea ? `&LineaId=${linea}` : '');

            let nombre = "Compras por articulo";
            await procesoPdf(loading8, url, nombre, resetFilterForm8);
        }

        const procesoExcel = async (loading, urls, name, reset) => {
            loading.value = true;

            const res = await ApiService.dowloadFileGet(urls);
            const url = window.URL.createObjectURL(res.data);

            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            a.remove();

            loading.value = false;
            reset();
        }

        const procesoPdf = async (loading, url, name, reset) => {
            loading.value = true;

            const res = await ApiService.query(url);
            emit("emitData", res.data, name)

            loading.value = false;
            reset();
        }

        // Reseteos de formularios
        const resetxFamily4 = () => {
            if (formFilters8.value) {
                formFilters8.value.setFieldValue('classIds', '');
                formFilters8.value.setFieldValue('lineIds', '');
            }
        }

        const resetxClass4 = () => {
            if (formFilters8.value) {
                formFilters8.value.setFieldValue('lineIds', '');
            }
        }

        const resetFilterForm7 = () => {
            formFilters7.value.resetForm();
            resetFilterDateInitial(formFilters7)
            form7.value = null;
        }

        const resetFilterForm8 = () => {
            formFilters8.value.resetForm();
            resetFilterDateInitial(formFilters8)
            if (formFilters8.value) {
                formFilters8.value.setFieldValue('typeStatus', false);
            }
        }

        const resetFilterDateInitial = (formFilter) => {
            const endDate = new Date();
            const startDate = new Date(endDate);
            startDate.setDate(endDate.getDate() - 120);

            if (formFilter.value) {
                formFilter.value.setFieldValue('startDate', startDate.toISOString());
                formFilter.value.setFieldValue('endDate', endDate.toISOString());
            }
        };

        //Combo options
        const getComboOptions = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getCombFamily = (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combos?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboLine = (resource, container, parentId = "", subParentId = "", filter = "") => {
            let url = `${resource}/${parentId}/${subParentId}/combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboUnidad = async (resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        //Buscadores
        const handleSearchProducts = (name) => {
            if(name.trim().length >= 3){
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const searchSuppliers = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const searchUnidad = async (filter) => {
            await getComboUnidad("MeasurementUnits", unidad, "", filter);
        }

        const searchCasher = async (filter) => {
            if (filter.trim().length >= 3) {
                await getComboOptions("Cashiers", cashiers, "", filter);
            }
        }

        const searchFamily = async (filter) => {
            if (filter.trim().length >= 3) {
                await getCombFamily("Families", family, "", filter);
            }
        }

        const searchSubFamily4 = async (filter) => {
            if(formFilters8.value.values.familyIds != null){
                await getCombFamily("SubFamilies", subfamily, formFilters8.value.values.familyIds, filter);
            }
        }

        const searchLinea4 = async (filter) => {
            if(formFilters8.value.values.familyIds != null && formFilters8.value.values.classIds != null){
                await getComboLine("Lines", linea, formFilters8.value.values.familyIds, formFilters8.value.values.classIds, filter);
            }
        }

        const searchUsers = (filterName) => {
            if (filterName.trim().length >= 3) {
                moduleForm.getComboUser(filterName);
            }
        }

        const searchCustomers = debounce((filterText) => {
            if (filterText.trim().length >= 3) {
                tableModule.getComboCustomer(filterText);
            }
        }, 550, { leading: false, trailing: true });

        return {
            idCollapse7,
            idCollapse8,
            searchPurchasePeriod,
            searchPurchaseArticle,
            formFilters7,
            formFilters8,
            btnFilters7,
            btnFilters8,
            btnFilters7_1,
            btnFilters8_1,
            selectionAction,
            validationSchemaFilters7,
            validationSchemaFilters8,
            customerOptions,
            typeStatus,
            typeReport7,
            typeReport8,
            productsCombo,
            productsLoading,
            userOptions,
            comboSupplier,
            comboCashier,
            comboFamily,
            comboSubFamily,
            comboLine,
            comboUnidad,
            handleSearchProducts,
            searchCustomers,
            searchSuppliers,
            searchUsers,
            searchCasher,
            searchUnidad,
            searchFamily,
            searchSubFamily4,
            searchLinea4,
            form7,
            isViewBtn7,
            resetxFamily4,
            resetxClass4,
            loading7,
            loading7_1,
            loading8,
            loading8_1
        };
    },
    watch: {
        'formFilters8.values.familyIds': function(newVal) {
            this.resetxFamily4();
        },
        'formFilters8.values.classIds': function(newVal) {
            this.resetxClass4();
        },
    }
});
</script>

<template>
    <div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse7}`"
                aria-expanded="true"
                :aria-controls="idCollapse7"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.COMPRAS_PERIODO') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse7">
                <Form as="el-form" @submit="searchPurchasePeriod" :validation-schema="validationSchemaFilters7"  ref="formFilters7">
                    <div class="row g-9 mb-8">
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select v-model="form7" :name="'reportPurchase'" :options="typeReport7">
                            </Select>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_SUPPLIER') }}</label>
                            <SelectFilterRemote :name="'supplierIds'" :options="comboSupplier"
                                                @search="searchSuppliers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-3 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FOLIO') }}</label>
                            <InputText :name="'folio'">

                            </InputText>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button v-show="isViewBtn7" type="submit" class="btn btn-success me-3" ref="btnFilters7_1" @click="selectionAction = 'excel'">
                            <span v-if="loading7_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters7" @click="selectionAction = 'report'">
                            <span v-if="loading7" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="card mb-3 mb-xl-3">
            <div
                class="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                :data-bs-target="`#${idCollapse8}`"
                aria-expanded="true"
                :aria-controls="idCollapse8"
            >
                <div class="card-title m-0">
                    <h3 class="fw-bolder ml-2 color-text">{{ $t('LABELS.COMPRAS_ARTICULO') }}</h3>
                </div>
            </div>
            <div class="collapse hide m-10" :id="idCollapse8">
                <Form as="el-form" @submit="searchPurchaseArticle" :validation-schema="validationSchemaFilters8"  ref="formFilters8">
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.REPORT') }}</label>
                            <Select :name="'reportPurchase'" :options="typeReport8">
                            </Select>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.DATE') }}</label>
                            <DatePickerRange></DatePickerRange>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_COMPLETO') }}</label>
                            <Select :name="'typeStatus'" :options="typeStatus" :defaultValue="true">
                            </Select>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_SUPPLIER') }}</label>
                            <SelectFilterRemote :name="'supplierIds'" :options="comboSupplier"
                                                @search="searchSuppliers"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_PRODUCT') }}</label>
                            <SelectFilterRemote :name="'productIds'" :options="productsCombo"
                                                @search="handleSearchProducts"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_UNIDAD') }}</label>
                            <SelectFilterRemote :name="'unidadIds'" :options="comboUnidad"
                                                @search="searchUnidad"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="row g-9 mb-8">
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_FAMILY') }}</label>
                            <SelectFilterRemote :name="'familyIds'" :options="comboFamily"
                                                @search="searchFamily"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_CLASS') }}</label>
                            <SelectFilterRemote :name="'classIds'" :options="comboSubFamily"
                                                @search="searchSubFamily4"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                        <div class="col-md-4 col-xs-12 fv-row">
                            <label>{{ $t('LABELS.NAME_LINE') }}</label>
                            <SelectFilterRemote :name="'lineIds'" :options="comboLine"
                                                @search="searchLinea4"
                                                :placeholder="'Filtro por nombre'">
                            </SelectFilterRemote>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-success me-3" ref="btnFilters8_1" @click="selectionAction = 'excel'">
                            <span v-if="loading8_1" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_EXCEL') }}
                        </button>
                        <button type="submit" class="btn btn-success" ref="btnFilters8" @click="selectionAction = 'report'">
                            <span v-if="loading8" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ $t('BUTTONS.GENERATE_REPORT') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "es": {
        "LABELS": {
            "VENTAS_PERIODO": "Reporte de ventas por período",
            "VENTAS_ARTICULO": "Reporte de ventas por artículo",
            "VENTAS_CLIENT": "Reporte de ventas por cliente",
            "VENTAS_SALESX": "Reporte de ventas generales por ...",
            "VENTAS_COMPARACION": "Reporte de ventas por comparación",
            "VENTAS_AGENTX": "Ventas por agentes",
            "COMPRAS_PERIODO": "Reporte de compras por período",
            "COMPRAS_ARTICULO": "Reporte de compras por articulo",
            "NAME_CUSTOMER": "Clientes",
            "NAME_AGENT": "Agente",
            "NAME_UNIDAD": "Unidad",
            "NAME_CASHIER": "Caja",
            "NAME_COMPLETO": "Estatus",
            "NAME_SUPPLIER": "Proveedor",
            "NAME_PRODUCT": "Producto",
            "NAME_FAMILY": "Familia",
            "NAME_CLASS": "Clase",
            "NAME_LINE": "Línea",
            "NAME_FOLIO": "Folio",
            "DATE": "Fecha",
            "TAX": "Impuestos",
            "TRANSACTION": "Tipo de transacción",
            "REPORT": "Reporte por:"
        },
        "BUTTONS": {
            "CLEAN_FILTERS_FORM": "Limpiar",
            "GENERATE_REPORT": "Generar reporte",
            "GENERATE_EXCEL": "Generar excel"
        }
    }
}
</i18n>

<style lang="scss">
    .color-text{
        color: rgba(0,0,0,.5);
    }
</style>