import OptionSelect from "@/models/shared/OptionSelect";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GeneralService from "@/core/services/interfaces/GeneralService";
import MessageService from "@/core/services/interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import { MovementType } from "@/core/enums/movement-types";


export default class MovementTypeComboService implements GeneralService {
   
    apiController = '/MovementTypes';
    messageService: MessageService = new SwalMessageService();

    async searchCombo(movementType: MovementType, filterName: string, isAutomatic: boolean): Promise<AxiosResponse<OptionSelect[]>> {
        return ApiService.get<Array<OptionSelect>>(this.apiController,
            `combo/${movementType}`
            + `?criteria=${filterName}`
            + `&isAutomatic=${isAutomatic}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}