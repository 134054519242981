export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ARTICULOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de articulos"])},
          "CANTIDAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de articulos"])},
          "ARTICULOS_PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de articulos por precio"])},
          "RANGO_INICIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de precio inicial"])},
          "RANGO_FINAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de precio final"])},
          "ARTICULOS_MOVEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte de articulos por movimientos"])},
          "KARDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kardex"])},
          "RESURTIDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurtido"])},
          "NAME_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
          "NAME_AGENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
          "NAME_UNIDAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad"])},
          "NAME_CASHIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caja"])},
          "NAME_COMPLETO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])},
          "NAME_SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
          "NAME_PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "NAME_FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia"])},
          "NAME_CLASS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase"])},
          "NAME_LINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea"])},
          "NAME_FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
          "TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos"])},
          "TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de transacción"])},
          "REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporte por:"])}
        },
        "BUTTONS": {
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
          "GENERATE_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar reporte"])},
          "GENERATE_EXCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar excel"])}
        }
      }
    }
  })
}
